import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { EPersona, GridConstants } from '@norfolk-southern/accessns-components';
import { IColumnRender } from '@norfolk-southern/accessns-components/lib/grid/models/grid-internal.interface';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellClassParams,
  ColGroupDef,
  Column,
  ColumnGroup,
  IHeaderColumn,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community';
import { differenceInDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { StatusCodes } from 'http-status-codes';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { IndividualConfig } from 'ngx-toastr';
import { from, of } from 'rxjs';
import { delay, filter, switchMap } from 'rxjs/operators';

import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import { ECountryCode } from '@ruby/shared/enums/common.enums';
import { IGridPrintTitle } from '@ruby/shared/models/commons/grid.interface';
import { IECRMetric } from '@ruby/shared/models/ecr/ecr.interface';
import { IError } from '@ruby/shared/models/request/error.interface';
import { NSDateUtils } from '@ruby/shared/services/utils/ns-date.utils';

export class NSCommonConstants {
  static hyperLinkPattern =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  static readonly passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/;
  static readonly supportLinks: Array<{ name: string; link: string; label?: string }> = [
    {
      name: 'Training Material',
      link: 'http://www.nscorp.com/content/accessns.html',
      label: 'Training Material link'
    },
    {
      name: 'Email Support',
      link: 'mailto:AccessNSFeedback@nscorp.com',
      label: 'Email Address'
    },
    {
      name: '1-800-635-5768',
      link: 'tel:1800 6355768',
      label: 'Helpline contact number'
    },
    {
      name: 'Additional Contacts',
      link: 'http://www.nscorp.com/content/nscorp/en/contact-us.html',
      label: 'Additional Contacts link'
    }
  ];
  static readonly browsers: Array<{ name: string; link: string; img: string }> = [
    {
      name: 'Google Chrome',
      link: 'https://www.google.com/chrome/',
      img: 'https://www.google.com/chrome/static/images/chrome-logo.svg'
    },
    {
      name: 'Firefox',
      link: 'https://www.mozilla.org/en-US/firefox/',
      img: 'https://www.mozilla.org/media/protocol/img/logos/firefox/browser/logo-md.f0603b4c28b4.png'
    },
    {
      name: 'Microsoft Edge',
      link: 'https://www.microsoft.com/en-us/edge',
      img: 'https://upload.wikimedia.org/wikipedia/commons/9/98/Microsoft_Edge_logo_%282019%29.svg'
    },
    {
      name: 'Safari',
      link: 'https://support.apple.com/en-us/HT204416',
      img: 'https://www.apple.com/v/safari/k/images/overview/safari_icon__ep64chrczuky_medium_2x.jpg'
    }
  ];
  static readonly mobileApps: Array<{
    name: string;
    linkAndroid: string;
    linkIOS: string;
    img: string;
    description: string;
  }> = [
    {
      name: 'NS Trax',
      linkAndroid: 'https://play.google.com/store/apps/details?id=com.nscorp.trax',
      linkIOS: 'https://apps.apple.com/us/app/ns-trax/id1355696602',
      img: 'https://play-lh.googleusercontent.com/ESDQPD13ZOa_pmrBYOzo5ULikDEiH1GpSSio6CSM2pOPegohid6ETrNDvY1QlI9Wm6w=s180-rw',
      description: 'Manage your shipments with Norfolk Southern from the NS Trax mobile app.'
    },
    {
      name: 'NS Rating',
      linkAndroid: 'https://play.google.com/store/apps/details?id=com.nscorp.nsrating',
      linkIOS: 'https://apps.apple.com/us/app/ns-rating/id1435509936',
      img: 'https://play-lh.googleusercontent.com/BUvrFzSrSYxN_Wjl3FgmjJgvx8e29AzPx-HoLogbiWA2ysJTvUjDcM5-VHylkVEB9g=s180-rw',
      description: 'Get rates for shipments with Norfolk Southern using the NS Rating mobile app.'
    },
    {
      name: 'ExpressNS',
      linkAndroid: 'https://play.google.com/store/apps/details?id=com.MobileFirstExpressNS',
      linkIOS: 'https://apps.apple.com/us/app/expressns/id1014680903',
      img: 'https://play-lh.googleusercontent.com/H3Zu4QRxEosmMnHKIbBfyTL27bAYorJqPzjt_trWCom0ry8nbraiZfawZ3c_ioz7YA8=s180-rw',
      description: 'ExpressNS™ is a mobile application created by Norfolk Southern to make the Ingate, Outgate, On-Terminal,' +
        'and Pre-Gate procedures to our intermodal facilities easier for you.'
    }
  ];
  static readonly multipleEmailPattern = '(([a-zA-Z\-0-9\._]+@)([a-zA-Z\-0-9\.]+)[ ;, ]*)+';
  static readonly eBillProfilePattern = /^[a-zA-Z0-9, &()-]*$/;
  static readonly charOnlyPattern = /^[a-zA-Z, ]*$/;
  static readonly numbersOnlyPattern = /^[0-9, ]*$/;
  static readonly numbersWithDecimalsPattern = /^(\d*\.?\d{0,2})*$/;
  static readonly numbersOnlyWithoutCommaPattern = /^[0-9]*$/;
  static readonly specialCharacter = /^[a-zA-Z0-9, ]*$/;
  static readonly validEquipmentWithDash = /^[a-zA-Z]{2,4}-(0{0,4}[a-zA-Z]\d{5}|\d{1,10})$/;
  static readonly validEquipment = /^[a-zA-Z]{2,4}(\s)?[\d]{1,10}$/;
  static readonly validEquipmentGroup = /^([a-zA-Z]{2,4})\s?([\d]{1,10})$/;
  static readonly validEmbargoNumber = /^[a-zA-Z]{2,4}?[\d]{4,6}$/;
  static readonly validUnitWithAnyNumber = /^[a-zA-Z]{2,4}(\s)?[\d]/;
  static readonly validWaybill = /^[\d]{10}$/;
  static readonly validDraft = /^[\d]{8}$/;
  static readonly validRequestNumber = /^[\w\/\-\s]*$/i;
  static readonly unitsRangePattern = new RegExp('/([a-zA-Z]{2,4}(\\s)?[\\d]{1,10}(…|-)[a-zA-Z]{2,4}(\\s?)[0-9]+)|([\\w]{2,4}(\\s)' +
    '?[\\d]{1,10}(…|-)[\\d]+|[a-zA-Z]{2,4}(\\s)?[\\w\\d]*|[a-zA-Z]{2,4}(\\s)?[\\d]{1,10}|[\\w]+)/g');
  static readonly replaceUnitPrefix = /^[a-zA-Z]{2,4}(\s)?/g;
  static readonly replaceSpacesRegex = /\s/g;
  static readonly replaceMultipleSpacesRegex = /\s+/g;
  static readonly replaceCommaOrSemicolonAtEndRegex = /[,;]$/;
  static readonly replaceLineBreaks = /(\r\n|\n|\r)/gm;
  static readonly replaceLineBreaksWithDoubleBackslash = /\\r?\\n/g;
  static readonly replacePhoneMaskChars = /\(|\)|-|\s/g;
  static readonly unitRange = /(…|-)/g;
  static readonly splitUnitRanges = /[…-]/;
  static readonly splitByCommaSemicolonNewLine = /[,;\n]+/;
  static readonly splitByPunctuation = /[,.;\n]/;
  static readonly specialCharCheck = /^[a-zA-Z0-9, .;-]*$/;
  static readonly stccRegexNum = /^[0-9]+$/;
  static readonly stccRegexAlpha = /^[a-zA-Z ]+$/;
  static readonly publicationNumberRegex = /^[a-zA-Z]{2,4}\s*[0-9]{1,5}$/;
  static readonly numbersOnlyPublicationPattern = /^[0-9]{4,10}/;
  static readonly fileExtensions = '.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.csv';
  static readonly alphanumericName = /^[a-zA-Z0-9 ]*$/;
  static readonly trimPattern = /^\s+|\s+$/gm;
  static readonly trimMultipleSpacesPattern = /\b[ ]{2,}\b/;
  static readonly phoneMaskPattern = /\D/g;
  static readonly validFTPDirectoryFormat = /^\/[a-zA-Z0-9!,.]*\/$|^\/|\/$/g;
  static readonly validEId = /^[\d]{6}$/;
  static readonly phoneMaskThreePattern = /^(\d{0,3})/;
  static readonly pipelineTitle = /(IM|UT|DRAY|OFFLINE)([\/_])/i;
  static readonly phoneMaskSixPattern = /^(\d{0,3})(\d{0,3})/;
  static readonly phoneMaskTenPattern = /^(\d{0,3})(\d{0,3})(\d{0,4})/;
  static readonly phoneMaskThirteenPattern = /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,4})/;
  static readonly textWithSpaces = /^[a-zA-Z&.,\-' ]+(\s{0,1}[a-zA-Z&.,\-' ])*$/;
  static readonly thirdDecimalCommas = /\B(?=(\d{3})+(?!\d))/g;
  static readonly charsOnlyAndLimited = /^(\s|[\w,._\-]){1,50}$/;
  static readonly numericPattern = /\D/g;
  static readonly zipPattern = /^\D{1,5}$/;
  static readonly shipperBOLNumber = /[^^{}*+']+/;
  static readonly validEquipmentWithSpace = /^[a-zA-Z]{2,4}(\s)[\d]{1,10}$/;
  static readonly zipPatternByCountryCode: Record<ECountryCode, RegExp> = {
    [ECountryCode.MX]: /^\d{5}$/,
    [ECountryCode.US]: /^([0-9]{5})(?:-([0-9]{4}))?$/,
    [ECountryCode.CA]: /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i
  };
  static readonly zipCodeMaskForUSPattern = /(\d{0,5})(\d{0,4})/;
  static readonly noResultsFoundMessage = 'No Results Found';
  static readonly noResultsFoundMessagePricePublications = 'Contact your NS Marketing Manager or the Customer' +
    ' Service Department at 1-800-635-5768 option 3 for your NS Marketing Manager contact information.';
  static readonly matchNumber = /[^0-9]/g;
  static readonly quoteNumbersPattern = /^[-,0-9]+$/;
  static readonly notFoundMessage = 'No records found.';
  static readonly loginRoute = '/home/dashboard';
  static readonly defaultInternalCustomer = 'NSCORP';
  static readonly defaultNS = 'NS';
  static readonly months: Array<string> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  static readonly weekDays: Array<string> = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  static readonly nsWorkingDays: Array<string> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  static readonly dayMillisecons = 24 * 3600 * 1000;
  static readonly stccStartsWithCriteria = 'STARTSWITH';
  static readonly stccContainsCriteria = 'CONTAINS';
  static readonly closeButtonToastrConfig: Partial<IndividualConfig> = { closeButton: true };
  static readonly delayTime = 300;
  static readonly noExceptionsFoundMessage = 'No Exceptions Found';
  static readonly nsTippyProps: NgxTippyProps = {
    theme: 'ns',
    interactive: true,
    placement: 'bottom-end',
    arrow: false,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-2, 4]
          }
        }
      ]
    }
  };

  static readonly monthsKey = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
}

export class NSErrors {
  static readonly unexpectedError: IError = {
    code: 'RB-GRL-000',
    title: 'Unexpected Error',
    message: 'Unexpected Error',
    status: StatusCodes.IM_A_TEAPOT
  };

  static readonly genericServiceError = {
    title: 'Something went wrong while processing your request',
    message: 'Please try again. If you continue experiencing issues, please contact help desk at: 1-800-635-5768, Option #4',
    code: 'RB-GRL-001'
  };

  static readonly unauthorizedError: IError = {
    code: String(StatusCodes.UNAUTHORIZED),
    title: 'Invalid Session',
    message: 'Your Session Expired, Please Login again',
    status: StatusCodes.UNAUTHORIZED
  };
}

export class NSCommons {
  static compareDate(input: Date, cell: Date): number | undefined {
    if (!input && !cell) {
      return 0;
    } else if (!input) {
      return -1;
    } else if (!cell) {
      return 1;
    } else {
      const formattedInput = new Date(input).setHours(0, 0, 0, 0);
      const formattedCell = new Date(cell).setHours(0, 0, 0, 0);
      if (formattedInput === formattedCell) {
        return 0;
      } else if (formattedCell < formattedInput) {
        return -1;
      } else if (formattedCell > formattedInput) {
        return 1;
      } else {
        return undefined;
      }
    }
  }

  static convertToDate(params: ValueGetterParams): Date | string {
    const value = NSCommons.getCellValue(params);
    return (value) ? new Date(value) : '';
  }

  static convertToAnyDecimal(value: number | undefined, decimal: number): string {
    return (value !== undefined) ? parseFloat(value.toString()).toFixed(decimal) : '';
  }

  static eBillConvertToDate(params: ValueGetterParams): Date | string {
    const value = NSCommons.getCellValue(params);
    return (value) ? NSCommons.formatNSDate(value, 'MM/dd/yyyy') : '';
  }

  static eBillConvertToTime(params: ValueGetterParams): Date | string {
    const value = NSCommons.getCellValue(params);
    return (value) ? NSCommons.formatNSDate(value, 'HH:mm') : '';
  }

  static eBillConvertToDateTime(params: ValueGetterParams): Date | string {
    const value = NSCommons.getCellValue(params);
    let format = 'MM/dd/yyyy hh:mm a';
    if (params.colDef && params.colDef.refData && params.colDef.refData.field) {
      format = params.data[params.colDef.refData.field] ? 'MM/dd/yyyy hh:mm a' : 'MM/dd/yyyy';
    }
    return (value) ? NSCommons.formatNSDate(value, format) : '';
  }

  static covertToYesNo(params: ValueGetterParams, full?: boolean): string {
    if (params.data) {
      const value = NSCommons.getCellValue(params);
      if (full) {
        return value ? 'Yes' : 'No';
      } else {
        return value ? 'Y' : 'N';
      }
    } else {
      return '';
    }
  }

  static isDSTActive(now: Date = new Date()): boolean {
    const invDate: Date = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    const diff: number = now.getTime() - invDate.getTime();
    const date: Date = new Date(now.getTime() - diff);

    const january: number = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july: number = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

    return Math.max(january, july) !== date.getTimezoneOffset();
  }

  static formatDate(params: ValueFormatterParams, format?: string): string {
    return NSCommons.formatNSDate(NSCommons.getCellValue(params), format);
  }

  static formatDateTimeZone(params: ValueFormatterParams, format?: string, locale?: string, timezone?: string): string {
    const value = this.getCellValue(params);

    if (!value) {
      return '';
    }
    return value ? NSDateUtils.formatNSDate(
      value,
      format || 'MM/dd/yyyy hh:mm a'
    ) : '';
  }

  static formatNSDate(value: string | number | Date, format?: string): string {
    if (!value) {
      return '';
    }

    if (typeof value === 'string' && Number(value)) {
      value = Number(value);
    }

    return NSDateUtils.formatNSDate(
      value,
      format || 'MM/dd/yyyy hh:mm a'
    );
  }

  static getCellValue(params: ValueGetterParams | ValueFormatterParams): string | Date | number {
    const coldDef = params.colDef;
    let value: Date | string = '';
    if (coldDef?.field && params.data) {
      const fields = coldDef.field.split('.');
      let data = params.data;
      fields.forEach((field: string) => (data) ? data = data[field] : '');
      value = data;
    }
    return value;
  }

  static getExpirationCellClass(params: CellClassParams): Array<string> {
    const classes = ['d-flex', 'justify-content-center', 'rounded'];
    if (params.value) {
      const daysToExpire: number = NSCommons.getDateDiffFromCurrentDate(params.value);
      let bgColor = '';
      let textColor = 'text-dark';
      if (daysToExpire <= 0 && params.data.status === 'ACTIVE') {
        bgColor = 'bg-danger';
        textColor = 'text-white';
      } else if (daysToExpire >= 1 && daysToExpire <= 8) {
        bgColor = 'bg-warning-light';
        textColor = 'text-white';
      } else if (daysToExpire > 8 && daysToExpire <= 30) {
        bgColor = 'bg-primary';
      }
      classes.push(bgColor);
      classes.push(textColor);
    }
    return classes;
  }

  static getDateDiffFromCurrentDate(date: number): number {
    return differenceInDays(date, new Date());
  }

  static convertToDecimal(node: ValueGetterParams): number {
    if (node.colDef.field && node.data && node.data[node.colDef.field] && (node.data[node.colDef.field]).toString().trim()) {
      return (node.data[node.colDef.field].toFixed(0)).replace(NSCommonConstants.thirdDecimalCommas, ',');
    }
    return 0;
  }

  static currencyValueFormatter(value: number | undefined, sign: string): string {
    if (typeof value === 'number') {
      const numParts = value.toFixed(2).split('.');
      const formatted = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `${ sign }${ formatted }.${ numParts[1] }`;
    }
    return '';
  }

  static decimalValueFormatter(value: number | undefined): string {
    if (typeof value === 'number') {
      const numParts = value.toString().split('.');
      const formatted = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return numParts[1] ? `${ formatted }.${ numParts[1] }` : formatted;
    }
    return '';
  }

  static gridHeader(customer?: ICustomer): IGridPrintTitle {
    let title = '';
    let info = '';
    if (customer) {
      switch (customer.type.name) {
        case EPersona.IP:
          title = customer.customerName;
          info = `${ customer.locations[0].locationName } - ${ customer.locations[0].classCode }`;
          break;
        case EPersona.IM:
        case EPersona.DRAY:
          title = `${ customer.customerName } - (${ customer.customerIdentifier })`;
          info = customer.locations.map((location: ILocation) => location.locationName).join(', ');
          break;
        case EPersona.SL:
        case EPersona.UT:
          title = customer.customerName;
          info = customer.locations.map((location: ILocation) => location.locationName).join(', ');
          break;
        case EPersona.OFFLINE:
        case EPersona.FREIGHT_FORWARD:
          title = customer.customerName;
          break;
      }
    }
    return { title, info };
  }

  static formatPayload(data: Array<{
    accepted: number;
    badOrder: number;
    eventDate: number;
    loadFill: number;
    orderFill: number;
    rejectedEvents: number;
    releasedEmpty: number;
    resetCount: number;
    totalAccepted: number;
  }>): IECRMetric {
    const accepted: Array<number> = [];
    const badOrders: Array<number> = [];
    const loadFills: Array<number> = [];
    const orderFills: Array<number> = [];
    const rejectedEvents: Array<number> = [];
    const releasedEmpties: Array<number> = [];
    const resetCounts: Array<number> = [];
    const totalAccepted: Array<number> = [];
    const eventDates: Array<string> = [];

    data.forEach(metric => {
      accepted.push(metric.accepted);
      totalAccepted.push(metric.totalAccepted);
      badOrders.push(metric.badOrder);
      loadFills.push(metric.loadFill);
      orderFills.push(metric.orderFill);
      rejectedEvents.push(metric.rejectedEvents);
      releasedEmpties.push(metric.releasedEmpty);
      resetCounts.push(metric.resetCount);
      eventDates.push(NSCommons.formatNSDate(metric.eventDate, 'MM/dd/yyyy'));
    });

    return {
      accepted,
      badOrders,
      loadFills,
      orderFills,
      rejectedEvents,
      releasedEmpties,
      resetCounts,
      totalAccepted,
      eventDates
    };
  }

  static formatPhone(node: ValueGetterParams): string {
    if (node.colDef.field && node.data && node.data[node.colDef.field] && (node.data[node.colDef.field]).toString().trim()) {
      const phone = (node.data[node.colDef.field]).replace(NSCommonConstants.phoneMaskPattern, '');
      return phone.replace(NSCommonConstants.phoneMaskTenPattern, '($1) $2-$3');
    }
    return '';
  }

  static formatEmail(data: string): string {
    return data.split(NSCommonConstants.splitByCommaSemicolonNewLine)
      .map((value: string) => value.trim())
      .filter((value: string) => (value !== ''))
      .join(';');
  }

  static transformEmailsStringToArray(value: string): Array<string> {
    return value.split(NSCommonConstants.splitByCommaSemicolonNewLine)
      .map((str: string) => str.replace(NSCommonConstants.replaceSpacesRegex, '')
        .replace(NSCommonConstants.replaceCommaOrSemicolonAtEndRegex, '')
      ).filter(Boolean);
  }

  static rateComparator(src: string, org: string): number {
    if (!src && !org) {
      return 0;
    }
    if (!src) {
      return -1;
    }
    if (!org) {
      return 1;
    }
    if (src === org) {
      return 0;
    }
    return Number(src) > Number(org) ? 1 : -1;
  }

  static daysDiff(date1: Date, date2: Date): number {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  static readonly months = (): Array<{ key: string; value: string }> => {
    const months: Array<{ key: string; value: string }> = [];
    for (let i = 0; i < 12; i++) {
      const month = enUS.localize?.month(i, { width: 'abbreviated' });
      months.push({ key: String(i), value: month });
    }
    return months;
  };

  static readonly yearsRange = (yearUnder: number, yearsAbove: number): Array<{ key: string; value: string }> => {
    const years: Array<{ key: string; value: string }> = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - yearUnder; i < currentYear; i++) {
      years.push({ key: String(i), value: String(i) });
    }
    for (let i = currentYear; i < currentYear + yearsAbove; i++) {
      years.push({ key: String(i), value: String(i) });
    }
    return years;
  };

  static getRequiredValidator(form: UntypedFormGroup, name: string): boolean {
    const control = form && form.get(name)!;

    if (!control || !control.validator) {
      return false;
    }

    const validator: ValidationErrors | null = control.validator({} as AbstractControl);

    return (validator && validator.required);
  };

  static clearPhoneNumber(phone?: string): string | undefined {
    let value = phone || '';
    value = value.replace(/\(|\)|-|\s/g, '');
    return value ? value : undefined;
  }

  static toUpperCase(input: any): Record<string, unknown> | number | string | Array<any> {
    if (Array.isArray(input)) { return input.map(this.toUpperCase); }
    if (typeof input === 'string') { return input.toUpperCase(); }
    if (typeof input === 'number') { return input; }

    return Object.keys(input)
      .reduce((destination: Record<string, unknown>, key: string) => {
        if (Array.isArray(input[key])) {
          destination[key] = input[key].map(NSCommons.toUpperCase);
        } else if (input[key] && typeof input[key] === 'object') {
          destination[key] = NSCommons.toUpperCase(input[key]);
        } else if (typeof input[key] === 'string') {
          destination[key] = input[key].toUpperCase();
        } else {
          destination[key] = input[key];
        }
        return destination;
      }, {});
  }

  static numberValueDecimalFormatter(value: number = 0): string {
    value = value ? value : 0;
    const [number, decimals] = value.toString().split('.');
    const formattedNumber = number.replace(NSCommonConstants.thirdDecimalCommas, ',');
    const decimalsPad = decimals?.length === 1 ? decimals.padEnd(2, '0') : decimals;
    return formattedNumber + (decimals ? `.${ decimalsPad }` : '');
  }

  static isProdEnv(url: string): boolean {
    return /accessns\.nscorp\.com/g.test(url);
  }

  static isQaEnv(url: string): boolean {
    return /accessnsqa/.test(url) || /-qa.apps/.test(url);
  }
}

export class PDFPrintGenerator {
  static print(title: string, content: string, customTag = 'print-generator', pageOrientation = 'portrait'): void {
    from(fetch(`/${ this.getStylesCSSFilename() }`)).pipe(
      filter((response: Response) => response.ok),
      switchMap((response: Response) => response.text()),
      switchMap((style: string) => {
        const styles = `
          <style>
            @page {
              size: ledger ${ pageOrientation };
            }
          </style>
          <style>${ style }</style>
        `;
        const pageTitle = document.title;
        const iFrame = document.createElement('iframe');
        document.body.appendChild(iFrame);
        const win = iFrame.contentWindow;
        document.title = `${ title } ${ NSCommons.formatNSDate(new Date().toString()) }`;
        win?.document.open();
        win?.document.write(this.getPage(title, content, styles, customTag));

        return of({ iFrame, pageTitle });
      }),
      delay(500)
    ).subscribe(({ iFrame, pageTitle }: { iFrame: HTMLIFrameElement; pageTitle: string }) => {
      iFrame.focus();
      iFrame.contentWindow?.print();
      setTimeout(() => {
        document.body.removeChild(iFrame);
      }, 4000);
      document.title = pageTitle;
    });
  }

  static parseGrid(grid: AgGridAngular): string {
    const groupColumns = grid.columnApi.getAllDisplayedColumnGroups()?.map((column: IHeaderColumn) => {
      const colGroupDef: ColGroupDef | null = (column as ColumnGroup).getProvidedColumnGroup().getColGroupDef();
      return { groupName: colGroupDef?.headerName || '', children: colGroupDef?.children?.length || 0 };
    });
    const columns = grid.columnApi.getAllDisplayedColumns()
      .filter((column: Column) => column.getColDef().type !== 'excludeExport')
      .map((column: Column) => {
        const columnDef = column.getColDef();
        return {
          columnId: columnDef.field || column.getColId(),
          columnName: columnDef.headerName,
          type: columnDef.type
        };
      });
    const records: Array<{ rowIndex: number; rowRecord: Array<string> }> = [];
    grid.api.forEachNodeAfterFilterAndSort((node: RowNode, index: number) => {
      const printData: { rowIndex: number; rowRecord: Array<string> } = { rowIndex: index, rowRecord: [] };
      columns.forEach((column: IColumnRender) => {
        const cellData = (grid.api.getValue(column.columnId, node) && column.type === 'dateColumn')
          ? NSCommons.formatNSDate(grid.api.getValue(column.columnId, node))
          : grid.api.getValue(column.columnId, node);
        if (cellData && column.type === 'dateColumn') {}
        printData.rowRecord.push(cellData || '');
      });
      records.push(printData);
    });
    return `
      <table>
        <thead>
          <tr>${ groupColumns?.map(
      col => `<th class="bg-primary border p-1" colspan="${ col.children }">${ col.groupName }</th>`
    ).join('') }</tr>
          <tr>
            ${ columns.map((item) => `<th class="bg-primary border p-1">${ item.columnName }</th>`).join('') }
          </tr>
          ${ records.map((record) =>
      `<tr> ${ record.rowRecord.map((columnData) =>
        `<td class="border p-1">${ columnData }</td>`
      ).join('') }
              </tr>`).join('') }
        </thead>
      </table>`;
  }

  private static getStylesCSSFilename(): string {
    const headLinks: Array<HTMLLinkElement> = [].slice.call(document.getElementsByTagName('link'));
    const cssFiles: Array<string> = headLinks.map(
      (link: HTMLLinkElement) => link.getAttribute('href') || ''
    ).filter((filename: string) => filename.startsWith('styles'));
    const [stylesFilename] = cssFiles;

    return stylesFilename || 'styles.css';
  }

  private static getPageHeader(title: string): string {
    return `
      <div class="justify-content-between flex-row pb-2">
        <p>NSCORP</p>
        <h3 class="text-uppercase">${ title }</h3>
        <div style="text-align: right">${ NSCommons.formatNSDate(new Date().toString()) }</div>
      </div>
    `;
  }

  private static getPage(title: string, content: string, styles: string, customTag: string): string {
    return `
      <html>
        <head>
          <title>&nbsp</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/solid.min.css"
          integrity="sha512-xIEmv/u9DeZZRfvRS06QVP2C97Hs5i0ePXDooLa5ZPla3jOgPT/w6CzoSMPuRiumP7A/xhnUBxRmgWWwU26ZeQ=="
          crossorigin="anonymous" referrerpolicy="no-referrer" />
          ${ GridConstants.printStyle }
          ${ styles }
        </head>
        <body ${ customTag }>
          ${ this.getPageHeader(title) }
          ${ content }
        </body>
      </html>
    `;
  }
}
