export enum EGridTimeFrame {
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_TWELVE_MONTHS = 'LAST_TWELVE_MONTHS',
  LAST_EIGHTEEN_MONTHS = 'LAST_EIGHTEEN_MONTHS',
  LAST_TWENTY_FOUR_MONTHS = 'LAST_TWENTY_FOUR_MONTHS',
  LAST_THREE_YEARS = 'LAST_THREE_YEARS',
  TODAY = 'TODAY',
  CURRENT = 'CURRENT',
  YESTERDAY = 'YESTERDAY',
  LAST_TWO_DAYS = 'LAST_TWO_DAYS',
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS'
}

export enum EFileType {
  CSV = 'csv',
  EXCEL = 'excel',
  PDF = 'pdf'
}

export enum ERestMethod {
  GET = 'GET',
  POST = 'POST'
}

export enum EMenuPath {
  UT_LOADING_STATUS = '/api/v1/unit-train/unit-train/report/loading-status'
}

export enum ECountryCode {
  US = 'US',
  CA = 'CN',
  MX = 'MX'
}

export enum EDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export enum EDayOfTheWeekShort {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
}
