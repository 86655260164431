import { createSelector } from '@ngrx/store';

import { IContact, IUserInfo } from '@ruby/modules/account/models/contact.interface';
import { hasIpCustomers, selectCurrentCustomerSelected } from '@ruby/modules/customer-select/store/selectors/customers.selectors';
import { selectAssignedCustomers } from '@ruby/modules/customer-select/store/selectors/offline-customers.selectors';
import { selectUserRacf } from '@ruby/modules/security/store/selectors/authorizations.selectors';
import {
  hasAuthorization,
  hasAuthorizations,
  hasRole,
  hasRoles,
  isInternalRole,
  selectAuthorizations,
  selectRoles
} from '@ruby/modules/security/store/selectors/authorizations.selectors';
import { selectUserInformation } from '@ruby/modules/security/store/selectors/user-information.selectors';
import { EAuthorization } from '@ruby/shared/enums/authorizations.enums';
import { EUserType } from '@ruby/shared/enums/customer-select.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';

export {
  selectUserRacf,
  selectRoles,
  selectAuthorizations,
  hasRole,
  hasRoles,
  hasAuthorization,
  hasAuthorizations,
  isInternalRole,
  selectUserInformation,
  selectCurrentCustomerSelected,
  selectAssignedCustomers
};

export const selectUserInfoState = createSelector(
  selectRoles,
  selectUserInformation,
  selectUserRacf,
  isInternalRole,
  (roles: Array<string>, contact?: IContact, racfId?: string, isInternal?: boolean) => ({
    contact,
    roles,
    racfId,
    type: isInternal ? EUserType.INTERNAL : EUserType.EXTERNAL
  } as IUserInfo)
);

export const hasOCMAccess = createSelector(
  hasRole(ERole.ROLE_IP),
  hasRole(ERole.ROLE_Internal),
  hasRole(ERole.ROLE_SL),
  hasIpCustomers,
  hasAuthorization(EAuthorization.CASE_MANAGEMENT_USER),
  hasAuthorization(EAuthorization.FEATURE2_FNS),
  (isIP: boolean, isInternal: boolean, isSL: boolean,hasIPLocations: boolean, isOCMUser: boolean, hasFeature2ns: boolean) =>
    (isOCMUser || hasFeature2ns) && ((!isIP || hasIPLocations) || (isIP && !hasIPLocations)) && !isInternal && !isSL
);

export const selectRubyAuthorizations = createSelector(
  hasOCMAccess,
  (hasOCM: boolean) => [
    ...(hasOCM ? [EAuthorization.RUBY_HAS_OCM] : [])
  ]
);

export const hasIpNoTypesPermissions = createSelector(
  hasRole(ERole.ROLE_IP),
  hasIpCustomers,
  (isIP: boolean, hasIpCustomersValue: boolean) => isIP && !hasIpCustomersValue
);
