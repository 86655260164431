import { createAction, props } from '@ngrx/store';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadCustomers = createAction(
  '[Customer] Load Customers'
);

export const loadCustomersSuccess = createAction(
  '[Customer] Load Customers Success',
  props<{ data: Array<ICustomer> }>()
);

export const loadCustomersFailure = createAction(
  '[Customer] Load Customers Failure',
  props<{ error: IError }>()
);

export const selectCustomer = createAction(
  '[Customer] Select Customer',
  props<{ data: ICustomer }>()
);

export const selectCustomerFailure = createAction(
  '[Customer] Select Customer Failure',
  props<{ error?: IError }>()
);

export const validateSelectedCustomer = createAction(
  '[Customer] Validate Selected Customer',
  props<{ data: ICustomer }>()
);

export const validateSelectedCustomerSuccess = createAction(
  '[Customer] Validate Selected Customer Success',
  props<{ data: Array<ICustomer> }>()
);

export const selectCustomerById = createAction(
  '[Customer] Selected Customer By Id',
  props<{ data: { classCode?: string; stationCode?: string } }>()
);
