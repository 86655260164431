import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EFileType, EPersona } from '@norfolk-southern/accessns-components';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

import { NSCommonConstants } from '@ruby/configs/common.constants';
import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import { EGridTitle, EGridType } from '@ruby/shared/enums/grid.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';
import { EUnitDetailsTab } from '@ruby/shared/enums/unit-details.enums';
import { IStationCarrier, IStationDetail } from '@ruby/shared/models/commons/station.interface';
import { IFavoriteData } from '@ruby/shared/models/favorites/favorites.interface';
import { IQuickSearch } from '@ruby/shared/models/quick-search/quick-search.interface';
import { UnitsParser } from '@ruby/shared/services/units-parser.service';
import { NSDateUtils } from '@ruby/shared/services/utils/ns-date.utils';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  file!: FormData;

  constructor(private toastr: ToastrService, private unitsParserSvc: UnitsParser) { }

  /**
   * Show Info Toastr
   *
   * @param title: string
   * @param message?: string
   * @param config?: Partial<IndividualConfig>
   * @returns void
   */
  showInfoToastr(title: string, message?: string, config?: Partial<IndividualConfig>): void {
    this.toastr.info(message, title, config);
  }

  /**
   * Show Success Toastr
   *
   * @param title: string
   * @param message?: string
   * @param config?: Partial<IndividualConfig>
   * @returns void
   */
  showSuccessToastr(title: string, message?: string, config?: Partial<IndividualConfig>): void {
    this.toastr.success(message, title, config);
  }

  /**
   * Show Warning Toastr
   *
   * @param title: string
   * @param message?: string
   * @param config?: Partial<IndividualConfig>
   * @returns void
   */
  showWarnToastr(title: string, message?: string, config?: Partial<IndividualConfig>): void {
    this.toastr.warning(message, title, config);
  }

  /**
   * Show Error Toastr
   *
   * @param title: string
   * @param message: string
   * @param config: Partial<IndividualConfig>
   * @returns void
   */
  showErrorToastr(title: string, message?: string, config?: Partial<IndividualConfig>): void {
    this.toastr.error(message, title, config);
  }

  /**
   * Build Request Object
   *
   * @summary This function will parse quick search entry data and return an object
   * @param data: string
   * @returns IQuickSearch
   */
  buildRequestObject(data: string): IQuickSearch {
    return {
      query: data
    };
  }

  /**
   * Capitalize a string
   *
   * @summary This function capitalize a string and remove underscore
   * @param text: string
   * @returns string
   */
  capitalize(text: string): string {
    return text.toLowerCase()
      .replace('_', ' ')
      .replace(/\b./g, (t) => t.toUpperCase());
  }

  /**
   * Serialize Favorites
   *
   * @summary This function serialize the favorites data
   * @param fav: IFavoriteData
   * @returns IFavoriteData
   */
  serializeFavorite(fav: IFavoriteData): IFavoriteData {
    const newFav: IFavoriteData = { ...fav };
    newFav.data = JSON.stringify(newFav.content);
    return newFav;
  }

  /**
   * Deserialize Favorites
   *
   * @summary This function deserializes the favorites data
   * @param fav: IFavoriteData
   * @returns IFavoriteData
   */
  deserializeFavorite(fav: IFavoriteData): IFavoriteData {
    const newFav: IFavoriteData = { ...fav };
    if (newFav.data) {
      newFav.content = JSON.parse(newFav.data);
    }
    return newFav;
  }

  /**
   * Equipments Validator
   *
   * @summary This function validate Equipments
   * @param checkEquipments: boolean
   * @param limit: number
   * @param checkMin: boolean
   * @returns ValidatorFn
   */
  equipmentsValidator(checkEquipments: boolean, limit: number = 1000, checkMin: boolean = true, includeWaybills: boolean = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const unitList = this.unitsParserSvc.parseUnits(control.value, limit, includeWaybills).totalEquipments;
      let response: { invalid: boolean; eqMaxLength: boolean } | Record<string, unknown> = {};
      if (checkEquipments && unitList > limit) {
        response = { eqMaxLength: true };
      } else if (checkMin && control.value) {
        response = (unitList < 1) ? { invalid: true } : {};
      } else if (!checkMin && control.value) {
        response = (unitList < 1) ? { invalid: true } : {};
      }
      return response;
    };
  }

  /**
   * Get Customers Ids
   *
   * @summary This function retrieve customer ids
   * @param customers: Array<ICustomer>
   * @param isInternalRole: boolean
   * @param hasShortLineRole: boolean
   * @returns string[]
   */
  getCustomersIds(customers: Array<ICustomer>, isInternalRole: boolean, hasShortLineRole: boolean): Array<string> {
    const result = new Set<string>();
    if (!isInternalRole) {
      customers.forEach((customer: ICustomer) => {
        if (hasShortLineRole) {
          if (customer.scac) {
            result.add(customer.scac);
          }
        } else if (customer.customerIdentifier.match(/^[0-9]{6}$/)) {
          result.add(customer.customerIdentifier);
        }
      });
    }
    return result.size > 0 ? Array.from(result.values()) : [NSCommonConstants.defaultInternalCustomer];
  }

  /**
   * Get Customers For Quick Search
   *
   * @summary This function retrieve customer for quick search format
   * @param customers: Array<ICustomer>
   * @param isInternalRole: boolean
   * @returns Array<Pick<ICustomer, 'customerName' | 'eid' | 'scac'>>
   */
  getCustomersForQuickSearch(customers: Array<ICustomer>, isInternalRole: boolean): Array<Pick<ICustomer, 'customerName' | 'customerIdentifier' | 'scac'>> {
    if (!isInternalRole) {
      return customers.filter(
        (value: ICustomer, index: number, arrayCustomers: Array<ICustomer>) => arrayCustomers
          .findIndex((customer: ICustomer) => (customer.customerName === value.customerName)) === index)
        .map((c: ICustomer) => (
          {
            customerName: c.customerName,
            customerIdentifier: c.customerIdentifier,
            scac: c.scac ? c.scac : ''
          }
        ));
    }
    return [
      {
        customerName: NSCommonConstants.defaultInternalCustomer,
        customerIdentifier: NSCommonConstants.defaultInternalCustomer,
        scac: ''
      }
    ];
  }

  /**
   * Get Quick Search Title
   *
   * @summary Gets a mapping from type to title
   * @param type The EGridType
   * @returns the title for the provided type
   */
  getQuickSearchTitle(type: EGridType): string {
    switch (type) {
      case EGridType.QUICK_DRAY:
        return EGridTitle.QUICK_DRAY;
      case EGridType.TRACK_AND_TRACE_UMLER:
        return EGridTitle.TRACK_AND_TRACE_UMLER;
      case EGridType.TRACK_AND_TRACE:
        return EGridTitle.TRACK_AND_TRACE;
      case EGridType.UMLER:
        return EGridTitle.UMLER;
      case EGridType.WAYBILL_VERIFICATION:
        return EGridTitle.WAYBILL_VERIFICATION;
      case EGridType.SCALE_WEIGHTS:
        return EGridTitle.SCALE_WEIGHTS;
      case EGridType.EVENT_HISTORY:
        return EGridTitle.EVENT_HISTORY;
      case EGridType.WAYBILL_HISTORY:
        return EGridTitle.WAYBILL_HISTORY;
      case EGridType.BAD_ORDERS_SUMMARY:
        return EGridTitle.BAD_ORDERS;
    }
    return 'Quick Search';

  }

  /**
   * Get Months
   *
   * @summary This function returns number of months based on input
   * @param count: number
   * @returns Array<{ name: string; id: number; }>
   */
  getMonths(count: number): Array<{ name: string; id: number }> {
    const monthName = NSCommonConstants.months;
    const dates: Array<{ name: string; id: number }> = [];
    let date = NSDateUtils.nsStartOfMonth(new Date());
    if (count === 11) {
      date = NSDateUtils.nsSubMonths(date, 1);
    }
    for (let i = 0; i <= count; i++) {
      dates.push({
        name: `${ monthName[date.getMonth()] }, ${ date.getFullYear() }`,
        id: date.getTime()
      });
      date = NSDateUtils.nsSubMonths(date, 1);
    }
    return dates;
  }

  /**
   * Get Customer Number
   *
   * @summary Get Customer Number
   * @param customer: ICustomer | undefined
   * @param isInternal: boolean
   * @returns string
   */
  getCustomerNumber(customer: ICustomer | undefined, isInternal: boolean): string {
    if (isInternal) {
      return NSCommonConstants.defaultInternalCustomer;
    } else if (customer?.scac) {
      return customer.scac;
    } else if (customer?.masterPrimarySix) {
      return customer.masterPrimarySix;
    }
    return '';
  }

  /**
   * Get Previous Month Start Date
   *
   * @summary Get Previous Month Start Date
   * @returns Date
   */
  getPreviousMonthStartDate(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);
  }

  /**
   * Get Previous Month End Date
   *
   * @summary Get Previous Month End Date
   * @returns Date
   */
  getPreviousMonthEndDate(): Date {
    const date = new Date();
    date.setDate(0);
    return date;
  }

  /**
   * Get Current Month Start Date
   *
   * @summary Get Current Month Start Date
   * @returns Date
   */
  getCurrentMonthStartDate(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  /**
   * Get Previous Date
   *
   * @summary Get Previous Date
   * @param previous: number
   * @returns Date
   */
  getPreviousDate(previous: number): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - previous, date.getDate());
  }

  /**
   * Get Tomorrow Date
   *
   * @summary Returns Tomorrow's Date
   * @returns Date
   */
  getTomorrowDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }

  /**
   * Get Type Role Mapping
   *
   * @summary This method maps the role to the line of business
   * @param role: ERole
   * @returns EPersona
   */
  getTypeRoleMapping(role: ERole): EPersona {
    switch (role) {
      case ERole.ROLE_IP:
        return EPersona.IP;
      case ERole.ROLE_IM:
        return EPersona.IM;
      case ERole.ROLE_SL:
        return EPersona.SL;
      case ERole.ROLE_UT:
        return EPersona.UT;
      case ERole.ROLE_Dray:
        return EPersona.DRAY;
      case ERole.ROLE_FF:
        return EPersona.FREIGHT_FORWARD;
      case ERole.ROLE_OFFLINE:
        return EPersona.OFFLINE;
      default:
        return EPersona.OFFLINE;
    }
  }

  /**
   * Mapping Role To Title
   *
   * @summary Mapping the role to string representation
   * @param role: ERole | string
   * @returns string
   */
  mappingRoleToTitle(role: string | ERole): string {
    switch (role) {
      case 'INDUSTRIAL_PRODUCT':
      case ERole.ROLE_IP:
        return 'Industrial Product';
      case 'SHORT_LINE':
      case ERole.ROLE_SL:
        return 'Short Line';
      case 'OFFLINE':
      case ERole.ROLE_OFFLINE:
        return 'Offline';
      case 'INTERMODAL':
      case ERole.ROLE_IM:
        return 'Intermodal';
      case 'UNIT_TRAIN':
      case ERole.ROLE_UT:
        return 'Unit Train';
      case ERole.ROLE_FF:
        return 'Freight Forwarder';
      case ERole.ROLE_Dray:
        return 'Dray';
      default:
        return role;
    }
  }

  /**
   * Deep Copy
   *
   * @summary Make a deep copy for an array, object or Date objects and returns the copy of the object or undefined in case an error
   * @param obj: Record<string, unknown>
   * @returns any
   */
  deepCopy<T extends Record<string, any>>(obj: T): any {
    let copy: any;

    // Handle null or undefined and if it is object
    if (!obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    copy = {};
    for (const key of Object.keys(obj)) {
      const objTmp = obj as Record<string, unknown>;
      copy[key] = this.deepCopy(objTmp[key] as Record<string, unknown>);
    }
    return copy;
  }

  /**
   * Deep Equal
   *
   * @summary Compare two objects deeply to make sure they are equal
   * @param object1: Record<string, unknown>
   * @param object2: Record<string, unknown>
   * @returns boolean
   */
  deepEqual<T extends Record<string, any>, U extends T>(object1: T, object2: T): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = (object1 as Record<string, unknown>)[key];
      const val2 = (object2 as Record<string, unknown>)[key];
      const areObjects = this.isObject(val1 as Record<symbol, unknown>) && this.isObject(val2 as Record<symbol, unknown>);
      if (areObjects && !this.deepEqual(val1 as Record<symbol, unknown>, val2 as Record<symbol, unknown>) || !areObjects && val1 !== val2) {
        return false;
      }
    }
    return true;
  }

  /**
   * IncludeCheckboxGroup Validator
   *
   * @summary includeCheckboxGroup Validator
   * @returns ValidatorFn
   */
  includeCheckboxGroupValidator(): ValidatorFn {
    return (checkBoxGroup: AbstractControl | UntypedFormGroup): ValidationErrors => {
      const group: UntypedFormGroup = checkBoxGroup as UntypedFormGroup;
      let result = {};
      let checked = 0;
      Object.keys(group.controls).forEach((key: string) => {
        if (group.controls[key].value !== true) {
          result = { valid: false };
        } else {
          checked++;
        }
      });
      return checked > 0 ? {} : result;
    };
  }

  /**
   * Extract Values From Object
   *
   * @summary Extract only those values who has data assigned
   * @param dataObject: Record<string, unknown>
   * @returns any
   */
  extractValuesFromObject<T extends Record<symbol, unknown>>(dataObject: Record<string, unknown>): any {
    const obj: any = {};

    Object.keys(dataObject).forEach(key => {
      if (dataObject[key]) {
        obj[key] = dataObject[key];
      }
    });

    return obj;
  }

  /**
   * Get Export File Name
   *
   * @summary Get export file name
   * @param name: string
   * @param type: EFileType
   * @returns string
   */
  getExportFileName(name: string, type: EFileType): string {
    let fileName = name;
    switch (type) {
      case EFileType.PDF:
        fileName = `${ fileName }.pdf`;
        break;
      case EFileType.EXCEL:
        fileName = `${ fileName }.xlsx`;
        break;
      default:
        fileName = `${ fileName }.${ type }`;
        break;
    }
    return fileName;
  }

  /**
   * Map Station & Carriers
   *
   * @summary Map stations and carriers
   * @param stations: Array<IStationDetail>
   * @returns IStationCarrier
   */
  mapStationCarriers(stations: Array<IStationDetail>): IStationCarrier {
    const carriersSet: Set<string> = new Set<string>();

    stations.forEach((station: IStationDetail) => {
      station.standardCarrierAlphaCodes.forEach((carrier: string) => carriersSet.add(carrier));
    });

    return { stations, carriers: Array.from(carriersSet).sort() } as IStationCarrier;
  }

  /**
   * Get Create Report Enable Config
   *
   * @summary Get the Create Report (Hide false) configuration to be visible in the grid
   * @returns Record<symbol, unknown>
   */
  getCreateReportEnableConfig(): Record<string, unknown> {
    return {
      groupBy: {
        grouping: false,
        expandCollapse: true
      },
      customizedReporting: false
    };
  }

  /**
   * Generate Short Line Locations
   *
   * @summary generate SL locations with the given customer information
   * @param customer: ICustomer
   * @returns Array<ILocation>
   */
  generateShortLineLocations(customer: ICustomer): Array<ILocation> {
    return customer.locations.map((location: ILocation) => ({
      scac: customer.scac,
      stationCode: location.stationCode
    } as ILocation));
  }

  /**
   * Generate Industrial Product Locations
   *
   * @summary generate IP locations with the given customer information
   * @param customer: ICustomer
   * @return Array<ILocation>
   */
  generateIndustrialProductsLocations(customer: ICustomer): Array<ILocation> {
    let locations: Array<ILocation> = [];
    if (customer.includedLocations && customer.includedLocations.length > 0) {
      locations = customer.includedLocations.map((location: ILocation) => ({
        classCode: location.classCode,
        stationCode: location.stationCode
      } as ILocation));
    } else if (customer.locations.length > 0) {
      locations = customer.locations.map((location: ILocation) => ({
        classCode: location.classCode,
        stationCode: location.stationCode
      } as ILocation));
    }
    return locations;
  }

  /**
   * Get Unit Detail Tab
   *
   * @summary Returns the Unit Detail Tab Type
   * @param view: EGridType
   * @return EUnitDetailsTab
   */
  getUnitDetailTab(view: EGridType): EUnitDetailsTab {
    let tab: EUnitDetailsTab = EUnitDetailsTab.TRACE;
    switch (view) {
      case EGridType.TRACK_AND_TRACE_UMLER :
      case EGridType.TRACK_AND_TRACE:
        tab = EUnitDetailsTab.TRACE;
        break;
      case EGridType.WAYBILL_VERIFICATION:
      case EGridType.WAYBILL_HISTORY:
        tab = EUnitDetailsTab.WAYBILL;
        break;
      case EGridType.EXCEPTION:
      case EGridType.EXCEPTIONS:
        tab = EUnitDetailsTab.EXCEPTIONS;
        break;
      case EGridType.UMLER:
        tab = EUnitDetailsTab.UMLER;
        break;
      case EGridType.SCALE_WEIGHTS:
        tab = EUnitDetailsTab.SCALE_WEIGHTS;
        break;
    }
    return tab;
  }

  private isObject<T extends Record<string, unknown>>(object: T): boolean {
    return object && typeof object === 'object';
  }
}
